<template>
  <div>
    <div class="contents" v-if="$store.state.customData.style == 1">
      <searchHeader />
      <div class="contrast">
        <div v-for="item in array" :key="item.id">
          <img :src="item.cover_img_path" alt="" />
          <b
            style="height: 40px; margin: 8px 0"
            class="cus duohangEllipsisTextTwo titleName"
          >
            {{ item.name }}{{ item.info }}
          </b>
          <p
            style="
              color: red;
              text-align: center;
              font-weight: 800;
              margin-bottom: 10px;
            "
          >
            ￥ {{ item.price }}
          </p>
          <div class="erjiCOLOR">商城品牌</div>
          <div class="pramesColor">
            {{ item.company_brand || "--" }}
          </div>
          <div class="erjiCOLOR">商品品牌</div>
          <div class="pramesColor">
            {{ (item.brand && item.brand.value) || "--" }}
          </div>
          <div v-for="its in item.com_sku_spec" :key="its.id">
            <div class="erjiCOLOR">{{ its.name }}</div>
            <div class="pramesColor">
              <span
                style="margin-right: 10px"
                v-for="(it, ins) in its.spec_value"
                :key="it.spec_value_id"
                >{{ it.spec_value_name }}
                <span v-if="its.spec_value.length - 1 != ins">;</span></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { get_sku_detail } from "@/assets/api/index.js";
import searchHeader from "../../index/components/searchTitles.vue";
export default {
  components: {
    searchHeader,
  },
  data() {
    return {
      array: [],
    };
  },
  created() {
    let ids = JSON.parse(this.$route.query.ids);
    ids.forEach((item, index) => {
      this.array.push({
        id: item,
      });
      this.query(item, index);
    });
  },
  methods: {
    query(item, index) {
      get_sku_detail({
        sku_id: item.id,
        spec_value_id: item.spec_value_id,
      }).then((res) => {
        this.array[index] = res.data;
        this.array = [...this.array];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  width: 1200px;
  margin: 0 auto;
}

.contrast {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  & > div {
    padding: 10px;
    box-sizing: border-box;
    width: 23%;
    background-color: $white;
    min-height: 600px;
    margin-bottom: 20px;
    img {
      width: 140px;
      height: 140px;
      margin: 0 auto;
      border-radius: 50%;
    }
  }
}

.erjiCOLOR {
  color: $textSecondary;
}
.pramesColor {
  color: $textPrimary;
  text-indent: 20px;
  margin-bottom: 10px;
}
</style>
