<template>
  <div>
    <div class="searchData" v-if="looktype == 1">
      <div v-if="$store.state.customData">
        <img @click="gotoPage($store.state.customData.logo.nav_url)" :src="$store.state.customData.logo.logo_path" />
      </div>
      <div>
        <div class="flexBt searInput">
          <div>
            <input :value="input" type="text" @input="inputText" />
          </div>
          <div @click="searchData" class="flexBt flexCt">
            <i class="el-icon-search"></i>
          </div>
          <div v-show="showSearch" class="searchKuang">
            <div @click="gotoThis(item.name)" v-for="item in searchList.slice(0, 10)" :key="item.id">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="hotSearch">
          <span @click="gotoPage(item.nav_url)" v-for="(item, index) in $store.state.customData.hot" :key="index">{{ item.keywords }}</span>
        </div>
        <div class="navSearch">
          <span @click="gotoPage(item.nav_url)" v-for="(item, index) in $store.state.customData.nav" :key="index">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="mobile-search" v-if="looktype == 2">
      <div class="mobile-search-box-out">
        <div class="mobile-search-box" @click="gotoPage($store.state.customData.logo.nav_url)" :style="{background: `url(${$store.state.customData.logo.logo_path})`, 'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'background-position': 'center'}">
          <!-- <img @click="gotoPage($store.state.customData.logo.nav_url)" :src="$store.state.customData.logo.logo_path"> -->
        </div>
        <div class="search-ipt">
          <input type="text" v-model="name" :placeholder="searchText">
          <i class="el-icon-search icon-color" style="width: 20px;height: 20px;text-align: center;line-height: 20px;" @click="toSearch()"></i>
        </div>
      </div>
      <div class="nav-search">
        <div class="nav-search-scroll">
          <div @click="gotoPage(item.nav_url)" v-for="(item, index) in  $store.state.customData.nav" :key="index">{{ item.name }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { get_search } from "@/assets/api/index.js";
export default {
  data() {
    return {
      input: "",
      showSearch: false,
      fangdou: true,
      searchList: [],
      save: "",
      timer: null,
      searchText: "",
      name: ''
    };
  },
  created() {
    if (this.$store.state.customData.hot.length != 0) {
      this.searchText = this.$store.state.customData.hot[0].keywords;
      let max = this.$store.state.customData.hot.length;
      let index = 0;
      let obj = {};
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        index++;
        if(index == max){
          index = 0;
        }
        obj = this.$store.state.customData.hot[index];
        this.searchText = obj.keywords;
      }, 3000);
    }
    if (this.$route.query.search) {
      this.input = this.$route.query.search;
    }
  },
  methods: {
    toSearch() {
      this.$router.push({
        path: "/shopStore/categoryList",
        query:{
          name: this.name
        }
      });
    },
    inputText(val) {
      this.input = val.target.value;
      if (!this.input) {
        this.showSearch = false;
      } else {
        this.query();
        this.showSearch = true;
      }
    },
    query() {
      if (this.fangdou) {
        this.fangdou = false;
        this.save = this.input;
        get_search({
          company_id: this.$store.state.cid,
          name: this.save,
        }).then(
          (res) => {
            console.log(res.data);
            this.searchList = res.data;
            setTimeout(() => {
              this.fangdou = true;
              if (this.save != this.input) {
                this.query();
              }
            }, 500);
          },
          () => {
            this.fangdou = true;
          }
        );
      }
    },
    gotoPage(val) {
      window.open(val);
    },
    gotoThis(val) {
      this.input = val;
      this.searchData();
    },
    searchData() {
      console.log(this.$route.path);
      if (this.$route.path == "/search") {
        let params = {
          name: this.input || "", //顶部搜索框 商品名称搜索
        };
        this.$emit("changeUpdate", params);
        this.showSearch = false;
      } else {
        this.$store.commit("changeIncludes", "");

        this.$nextTick(() => {
          this.$router.push({
            path: "/search",
            query: {
              search:
                this.input ||
                this.$store.state.customData.hot[0].keywords ||
                "商品",
            },
          });
          this.$store.commit("changeIncludes", "searchList");
        });
      }
    },
  },
};
</script>
<style>
body::-webkit-scrollbar {
  /* 隐藏浏览器默认的滚动条 */
  display: none !important;
}

body {
  /* 隐藏浏览器默认的滚动条 */
  -ms-overflow-style: none;
  /* IE 10+ */
  overflow: -moz-scrollbars-none;
  /* Firefox */
  scrollbar-width: none;
  /*firefox*/
}
</style>
<style lang="scss" scoped>
.searchData {
  height: 145px;
  background-color: $white;
  padding: 24px 250px 24px 25px;
  box-sizing: border-box;
  display: flex;
  & > div:nth-child(1) {
    width: 300px;
    // line-height: 80;
    text-align: center;
    margin-right: 25px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      display: inline-block;
      height: auto;
      max-height: 80px;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  & > div:nth-child(2) {
    flex: 1;
    overflow: hidden;
    .searInput {
      position: relative;
      border: 2px solid $primary;
      height: 40px;
      box-sizing: border-box;
      & > div:nth-child(1) {
        flex: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        input {
          width: 100%;
          height: 22px;
          padding-left: 8px;
          border: none;
        }
      }
      & > div:nth-child(2) {
        width: 62px;
        color: $white;
        background-color: $primary;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
      }
      .searchKuang {
        position: absolute;
        top: 37px;
        border: 2px solid $primary;
        background-color: $white;
        left: -2px;
        width: 100%;
        height: 400px;
        z-index: 99;
        & > div {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          cursor: pointer;
          &:hover {
            background-color: $primary;
            color: #fff;
          }
        }
      }
    }
    .hotSearch {
      margin-top: 8px;
      color: $textSecondary;
      height: 12px;
      & > span {
        margin-right: 20px;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .navSearch {
      margin-top: 14px;
      color: $textPrimary;
      height: 16px;
      & > span {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }
}
.mobile-search {
  width: 100vw;
  height: 145px;
  padding-left: 13px;
  box-sizing: border-box;
  overflow: hidden;
  background: linear-gradient(
    179.39deg,
    rgba(255, 149, 149, 1) -48.6%,
    rgba(252, 168, 168, 1) 29.16%,
    rgba(255, 255, 255, 1) 99.44%
  );
  .mobile-search-box-out {
    display: flex;
    align-items: center;
    .mobile-search-box {
      width: 30vw;
      height: 36px;
      display: flex;
      align-items: center;
     
      & > img {
        width: 100%;
        height: auto;
        max-height: 61px;
      }
    }
    padding: 16px 0;
    .search-ipt {
      width: 60vw;
      height: 35px;
      background: #fff;
      border-radius: 50px;
      overflow: hidden;
      margin-left: 10px;
      padding-left: 10px;
      box-sizing: border-box;
      & > input {
        width: 88%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
  }
  .nav-search {
    width: 100vw;
    overflow-x: auto;
    padding-top: 10px;
    box-sizing: border-box;
    .nav-search-scroll {
      min-width: 100vw;
      display: flex;
      align-items: center;
      white-space: nowrap;
      // justify-content: space-between;

      box-sizing: border-box;
      & > div {
        // max-width: 33.33%;
        // min-width: 60px;
        padding-right: 16px;
        font-size: 1em;
      }
    }
    &::-webkit-scrollbar {
      width: 0;
      display: none !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #e5f5ff;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: #e7eff4;
    }
  }
}
.icon-color {
  color: $primary;
}
</style>
